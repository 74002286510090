document.addEventListener("DOMContentLoaded", () => {
  const nameInput = document.querySelector("#free-roadmap-name-input");
  const emailInput = document.querySelector("#free-roadmap-email-input");
  const generateReportButton = document.querySelector("#generate-report-button");
  const generateGitHubReportButton = document.querySelector("#generate-github-report-button");
  const acceptPrivacyStatement = document.querySelector("#accept-privacy-statement");

  function checkInputs() {
    if (generateGitHubReportButton) {
      generateGitHubReportButton.disabled = !emailInput.value || !acceptPrivacyStatement.checked;
    }

    if (generateReportButton) {
      generateReportButton.disabled = !(nameInput.value && emailInput.value) || !acceptPrivacyStatement.checked;
    }
  }

  if (nameInput) {
    nameInput.addEventListener("input", checkInputs);
  }
  if (emailInput) {
    emailInput.addEventListener("input", checkInputs);
  }
  acceptPrivacyStatement.addEventListener("change", checkInputs);
});
