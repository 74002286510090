/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import "fastruby-io-styleguide"
require("@rails/ujs").start()
// require('channels')
import "../src/contact"
import "../src/conversion_tracking"
import "../src/form"
import "../src/header"
import "../src/our_services"
import "../src/scroll_to_section"
import "../src/free_roadmap"
import "../src/roadmap"
import "../src/testimonials-slider"
import "../src/slides"
import "../src/utils"
import "../src/external_js"
